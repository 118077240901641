import React from "react";
import { graphql } from "gatsby";
import ArticlesComponent from "../components/articles";
import Layout from "../components/layout";

export const query = graphql`
  query Category($slug: String!) {
    articles: allStrapiArticle(
      filter: { category: { slug: { eq: $slug } } }
    ) {
        edges {
            node {
                slug
                title
                category {
                    name
                }
                image {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 660,
                                layout: CONSTRAINED,
                                placeholder: BLURRED,
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                    }
                }
                author {
                name
                picture {
                    localFile { 
                        childImageSharp {
                                gatsbyImageData(
                                    width: 30,
                                    height: 30,
                                    layout: CONSTRAINED,
                                    placeholder: BLURRED,
                                    formats: [AUTO, WEBP, AVIF]
                                )
                            }
                        }
                    }
                }                
            }
        }
    }
    category: strapiCategory(slug: { eq: $slug }) {
      name
    }
  }
`;
const Category = ({ data }) => {
  const articles = data.articles.edges;
  const category = data.category.name;
  const seo = {
    metaTitle: category,
    metaDescription: `All ${category} articles`,
  };

  //console.log("categories", articles)

  return (
    <Layout seo={seo}>
      <div className="uk-section">
        <div className="uk-container uk-container-large">
          <h1>{category}</h1>
          <ArticlesComponent articles={articles} />
        </div>
      </div>
    </Layout>
  );
};
export default Category;